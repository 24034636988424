import React from 'react';

import About from '@myungsoo/base/pages/About';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

import * as config from '../../config';

const AboutPage = () => {
  const [loading, setLoading] = React.useState(false);

  return (
    <Layout loading={loading}>
      <Seo title="About" />
      <About config={config} onLoadingChange={setLoading} />
    </Layout>
  );
};

export default AboutPage;
